import React from 'react';
import './Quotes.scss';

const Quotes = () => {
  return (
    <div className="quotes-section">
      <div className="desktop-quote">
        <p>1100</p>
        <blockquote>"Life is too short for bad coffee" - Anonymous</blockquote>
        <blockquote>"Coffee, the favorite drink of the civilized world." - Thomas Jefferson</blockquote>
      </div>
      <div className="mobile-quote">
        <p>99</p>
        <blockquote>"Coffee is the best thing to douse the sunrise with." - Terri Guillemets</blockquote>
      </div>
    </div>
  );
};

export default Quotes;
