import React from 'react';
import './Testimonials.scss';
import Prof1 from '../../assets/images/prof1.png';
import Prof2 from '../../assets/images/prof2.png';
import Prof3 from '../../assets/images/prof3.png';
import Prof4 from '../../assets/images/prof4.png';

const testimonialsData = [
  {
    image: Prof2,
    name: 'Emily Davis',
    text: "I love the cozy atmosphere here. The pastries are delicious, and the coffee never disappoints. It's the perfect place to unwind."
  },
  {
    image: Prof1,
    name: 'Michael Johnson',
    text: "Great coffee, great service! The baristas are knowledgeable and always make my latte just right. I can't imagine starting my day anywhere else."
  },
  {
    image: Prof3,
    name: 'James Anderson',
    text: "This café has the best chai latte in the city. The free Wi-Fi and comfortable seating make it ideal for working. A hidden gem in Chicago!"
  },
  {
    image: Prof4,
    name: 'Sarah Miller',
    text: "All Meal Prep Café is my go-to spot every morning. Their espresso is always perfect, and the staff is incredibly friendly. Highly recommend!"
  }
];

const Testimonials = () => {
  return (
    <section className="testimonials">
      <h2 className="title">What others say</h2>
      <p className="subtitle">Read our customer testimonials to see why people love our services and keep coming back!</p>

      <div className="testimonials__grid">
        {testimonialsData.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <div className="testimonial__header">
              <img src={testimonial.image} alt={testimonial.name} className="testimonial__image" />
              <h3 className="testimonial__name">{testimonial.name}</h3>
            </div>
            <p className="testimonial__text">{testimonial.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
