import React from 'react';
import './Product.scss';
import ArrowLeftIcon from '../../assets/icons/arrow1.png';
import ArrowRightIcon from '../../assets/icons/arrow2.png';
import MacchiatoImage from '../../assets/images/macchiato.png';
import EspressoImage from '../../assets/images/espresso.png';

const productsData = [
  {
    id: 1,
    image: EspressoImage,
    title: 'Espresso',
    description: 'Rich, bold, and smooth, our espresso is the perfect shot of flavor to energize your day.'
  },
  {
    id: 2,
    image: MacchiatoImage,
    title: 'Macchiato',
    description: 'A harmonious blend of rich espresso and frothy milk, our macchiato delivers a perfect balance of flavors, ideal to energize your day.'
  }
];

const Product = () => {
  return (
    <div className="product-section">
      <div className="product-header">
        <h2>Our Specialties</h2>
        <div className="arrows">
          <img src={ArrowLeftIcon} alt="Previous" className="arrow"/>
          <img src={ArrowRightIcon} alt="Next" className="arrow"/>
        </div>
      </div>

      <div className="product-list">
        {productsData.map((product) => (
          <div key={product.id} className="product-item">
            <img src={product.image} alt={product.title} className="product-image" />
            <h3>{product.title}</h3>
            <p>{product.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
