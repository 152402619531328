import React from 'react';
import './Footer.scss';
import FooterLogo from '../../assets/images/footerlogo.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__logo-section">
          <img src={FooterLogo} alt="AMP Coffee Logo" className="footer__logo" />
        </div>
        <p className="footer__rights">© 2024 All Meal Prep Coffee - All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
