import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import Menu from './components/Menu/Menu';
import Product from './components/Products/Product';
import Quotes from './components/Quotes/Quotes';
import Contact from './components/Contact/Contact';
import Testimonials from './components/Testimonials/Testimonials';
import Footer from './components/Footer/Footer';

import './scss/main.scss';




function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Menu />
      <Product/>
      <Quotes/>
      <Contact/>
      <Testimonials/>
      <Footer/>
    </div>
  );
}

export default App;
