import React from 'react';
import './Hero.scss';
import FreshIcon from '../../assets/images/fresh.png';
import ToGoIcon from '../../assets/images/togo.png';
import PastriesIcon from '../../assets/images/pastries.png';
import OrganicIcon from '../../assets/images/organic.png'; // Dodano
import SweetsIcon from '../../assets/images/sweets.png';  // Dodano
import JuliusMeinlIcon from '../../assets/images/julius.png';
import CoffeeCup from '../../assets/images/coffe.png';  
import CoffeeBeans from '../../assets/images/coffe2.png'; 

const Hero = () => {
  return (
    <div className="hero">
      <img src={CoffeeCup} alt="Coffee Cup" className="hero-image-left" />
      <img src={CoffeeBeans} alt="Coffee Beans" className="hero-image-right" />
      
      <div className="hero-content">
        <div className='hero-text-desktop'>
            <h1 className="hero-title">
            Welcome to <span className="highlight">AMP Coffee</span> by Julius Meinl
            </h1>
            <p className="hero-description">
            All Meal Prep in collaboration with Julius Meinl - bringing Edgewater the best tasting European coffee out there! 
            Coffee is more than just a drink—it's an experience. From their meticulously sourced beans to their expert brewing techniques, 
            Julius Meinl has been consistently perfecting coffee since 1862. Every sip is designed to be savored. 
            Visit us and taste the difference in every cup!
            </p>
        </div>

        <div className="hero-text-mobile">
          <h1 className="hero-title-smaller">
            Where Every <span className="highlight">Cup</span> Tells a Story
          </h1>
          <p className="hero-description-smaller">
            "At AllMealPrep Coffee, we believe that coffee is more than just a drink—it's an experience. 
            From our meticulously sourced beans to our expert brewing techniques, every sip is designed to be savored. 
            Visit us and taste the difference in every cup."
          </p>
        </div>

        <div className="hero-buttons">
          <button className="button-primary">Shop Now</button>
          <button className="button-secondary">Our Cafe Menu</button>
        </div>

        <div className="icon-section">
          <div className="icon-holder">
            <img src={FreshIcon} alt="Fresh" />
          </div>
          <div className="icon-holder">
            <img src={ToGoIcon} alt="To Go" />
          </div>
          <div className="icon-holder">
            <img src={PastriesIcon} alt="Pastries" />
          </div>
          <div className="icon-holder"> {/* Dodano za Organic */}
            <img src={OrganicIcon} alt="Organic" />
          </div>
          <div className="icon-holder"> {/* Dodano za Sweets */}
            <img src={SweetsIcon} alt="Sweets" />
          </div>
        </div>

        <div className="powered-by">
          <img src={JuliusMeinlIcon} alt="Powered by Julius Meinl" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
