import React from 'react';
import './Menu.scss';
import CoffeeMenuImage from '../../assets/images/coffeMenu.png';

const Menu = () => {
  return (
    <div className="menu-section">
      <img src={CoffeeMenuImage} alt="Coffee Menu" className="coffee-menu-image" />
    </div>
  );
};

export default Menu;
