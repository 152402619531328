import React from 'react';
import './Contact.scss';
import MapImage from '../../assets/images/map.png';
import PhoneIcon from '../../assets/icons/phone.png';
import MailIcon from '../../assets/icons/mail.png';
import PinIcon from '../../assets/icons/pin.png';
import JuliusBarImage from '../../assets/images/juliusbar.png';
import AparatImage from '../../assets/images/aparat.png';

const Contact = () => {
  return (
    <section className="contact">
      <div className="contact__map-section">
        <h2 className="title">Location & Contact</h2>
        <img src={MapImage} alt="Map" className="contact__map-image" />
        <div className="contact__info">
          <div className="contact__info-item">
            <img src={PhoneIcon} alt="Phone Icon" className="contact__icon" />
            <span>+1 (312) 313-2011</span>
          </div>
          <div className="contact__info-item">
            <img src={MailIcon} alt="Mail Icon" className="contact__icon" />
            <span>info@allmealprep.com</span>
          </div>
          <div className="contact__info-item">
            <img src={PinIcon} alt="Location Icon" className="contact__icon" />
            <span>1100 W Bryn Mawr Ave, Chicago, IL 60660</span>
          </div>
        </div>
        <div className="contact__buttons">
        <button className="button-primary1">Order Now</button>
        <button className="button-secondary1">Our Specialties</button>
        </div>
      </div>

      <div className="contact__images-section">
        <img src={JuliusBarImage} alt="Julius Bar" className="contact__image" />
        <img src={AparatImage} alt="Aparat" className="contact__image" />
      </div>
    </section>
  );
};

export default Contact;