import React, { useState } from 'react';
import './Navbar.scss';
import Logo from '../../assets/images/logo.png';
import Burger from '../../assets/icons/burger.png';
import CloseIcon from '../../assets/icons/close.png';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img src={Logo} alt="AMP Coffee Logo" />
        </div>
        <div className="burger-icon" onClick={toggleMobileMenu}>
          <img src={Burger} alt="Menu" />
        </div>
        
        {/* Mobilni meni */}
        <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <div className="mobile-menu-header">
            <span>Navigation</span>
            <button className="close-menu" onClick={toggleMobileMenu}>
              <img src={CloseIcon} alt="Close Menu" />
            </button>
          </div>
          <ul className="mobile-links">
            <li><a href="#home" className="active">Home</a></li>
            <li><a href="#pastries">Pastriesss</a></li>
            <li><a href="#menu">Menu</a></li>
            <li><a href="#contact">Contact Us</a></li>
          </ul>
          <button className="button-secondary">Order Now</button>
        </div>

        <ul className="navbar-links">
          <li><a href="#home" className="active">Home</a></li>
          <li><a href="#pastries">Pastries</a></li>
          <li><a href="#menu">Menu</a></li>
          <li><a href="#contact">Contact Us</a></li>
        </ul>
        <button className="button-primary">Order Now</button>
      </div>
    </div>
  );
};

export default Navbar;
